import { sliderParams } from "../import/sliders";
import Swiper from "swiper";


// Отключение действий при нажатии на кнопки
export function preventClickDefault(elem) {
    elem.on("click", function (e) {
        e.preventDefault();
    });
}
// gets Date object from date string like "13.10.2024"
export function getDateFromFormat(datestring) {
    if (datestring.match(/\d{2}\.\d{2}\.\d{4}/) === null) return null;
    else {
        const parts = datestring.split(".");
        return new Date(parts[2], parts[1], parts[0]);
    }
}
export function getURLParam(paramName = "") {
    const params = new URLSearchParams(window.location.search);
    let value;
    if (paramName.length > 0) {
        value = params.getAll(paramName);
        if (value === "null") value = "";
        return value;
    } else {
        return params;
    }
}
/* Проверка поддержки webp, добавление класса webp или not-webp для HTML */
export function isWebp() {
    function testWebP(callback) {
        let webP = new Image();
        webP.onload = webP.onerror = function () {
            callback(webP.height == 2);
        };
        webP.src =
            "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    }
    testWebP(function (support) {
        let className = support === true ? "webp" : "no-webp";
        document.body.classList.add(className);
    });
}
/** set html element attributes */
export function setAttributes(el, attrs) {
    for (const key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}
export function removeAttributes(el, attrs) {
    for (const key in attrs) {
        el.removeAttribute(key);
    }
}
// wrap an element with another; super basic but makes it consistent across our apps
export function wrap(el, wrapper) {
    if (el?.parentNode) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }
}
export function createSliders(elt) {
    const childSliders = window.htmx.findAll(elt, ".swiper");
    const parentSlider = window.htmx.closest(elt, ".swiper");
    if (childSliders != null && childSliders.length > 0) {
        for (const slider of childSliders) {
            //console.log(childSliders);
            //console.log(slider.dataset.slider);
            if (typeof slider.dataset.slider !== "undefined" && typeof sliderParams[slider.dataset.slider] !== "undefined") {
                const params = sliderParams[slider.dataset.slider];
                new Swiper(slider, params);
            }
        }
    } else if (parentSlider != null) {
        console.log(parentSlider);
        if (typeof parentSlider.dataset !== "undefined" && typeof parentSlider.dataset.slider !== "undefined" && typeof sliderParams[parentSlider.dataset.slider] !== "undefined") {
            const params = sliderParams[parentSlider.dataset.slider];
            new Swiper(parentSlider, params);
        }
    }
}
export function getDirection() {
    const windowWidth = window.innerWidth;
    let direction = windowWidth <= 540 ? "horizontal" : "horizontal";
    return direction;
}
export function scrollToTargetAdjusted(id, offset) {
    let element = document.getElementById(id);
    let headerOffset = offset;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
    return false;
}
export function getElementHeight(elementID = "header") {
    let element = document.getElementById(elementID);
    return element.getBoundingClientRect().height;
}
//delay function
export function delayHandler(callback, ms) {
    let timer = 0;
    return function () {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}
export const ss_list = [];
export const ss_defaults = [];
export function ss_reset(ss_obj, index) {
    ss_obj.setSelected(ss_defaults[index]);
}
window.addEventListener("load", function () {
    isWebp();
    // functions.preventClickDefault($("form"));
});
window.addEventListener("resize", function () {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
    }, 100);
});
let timer;
window.addEventListener(
    "scroll",
    function () {
        if (timer !== null) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            const pageOffset = Math.round(window.pageYOffset);
            pageOffset > 300
                ? document.body.classList.add("scrolled")
                : document.body.classList.remove("scrolled");
        }, 100);
    },
    false,
);
export function getNextSibling(elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
}
export function camelize(s) {
    return s.replace(/-./g, x => x[1].toUpperCase());
}
export function inArray(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
    }
    return false;
}
export const mapPlacemarks = [
    {
        coords: [54.195095, 45.168686],
        content: {
            iconContent: "Поликлиника КИМ"
            , balloonContentHeader: "<a href = \"https://kim-rm.ru\" class=\"text-secondary\">Медицинский центр КИМ</a>"
            , balloonContentBody: "<b class=\"text-primary text-lead\">Поликлиника </b> <br/> г. Саранск, ул. Васенко 11 строение 2.<br/><a href=\"tel:88342222700\">8 (8342) 222-700</a>"
            , hintContent: "Поликлиника КИМ: ул. Васенко 11"
        },
        presets: {
            preset: "islands#nightStretchyIcon",
        }
    },
    {
        coords: [54.195883, 45.169333],
        content: {
            iconContent: "Стационар КИМ"
            , balloonContentHeader: "<a href = \"https://kim-rm.ru\" class=\"text-secondary\">Медицинский центр КИМ</a>"
            , balloonContentBody: "<b class=\"text-success\">Стационар </b> <br/> г. Саранск, ул. Васенко 15А строение 2.<br/><a href=\"tel:88342222700\">8 (8342) 222-700</a>"
            , hintContent: "Стационар КИМ: ул. Васенко 15Ас2"
        },
        presets: {
            preset: "islands#darkGreenStretchyIcon"
        }
    }
];