import { mapPlacemarks } from "../../../js/modules/functions";


var placemarks = [];
var myMap, popupMap, docMap = {};

// eslint-disable-next-line no-undef
ymaps.ready(init);
function init() {
    myMap = new ymaps.Map("footer-map", {
        center: [54.195556, 45.168831],
        zoom: 17,
    });
    popupMap = new ymaps.Map("popup-map", {
        center: [54.195556, 45.168831],
        zoom: 16,
    });
    if (document.getElementById("doc-map")) {
        docMap = new ymaps.Map("doc-map", {
            center: [54.195556, 45.168831],
            zoom: 17,
        });
    }
    if (mapPlacemarks.length > 0) {
        mapPlacemarks.forEach(el => {
            let mark = new ymaps.Placemark(el.coords, el.content, el.presets);
            if (mark) {
                myMap.geoObjects.add(new ymaps.Placemark(el.coords, el.content, el.presets));
                popupMap.geoObjects.add(mark);
                if (document.getElementById("doc-map")) docMap.geoObjects.add(mark);
                placemarks.push(mark);
            }
        });
    }
}
const links = document.querySelectorAll(".map-link");
if (links) {
    links.forEach(el => {
        el.addEventListener("click", function (e) {
            const target = e.currentTarget;
            if (target.dataset.map !== undefined && popupMap) {
                placemarks[target.dataset.map].balloon.open();
                popupMap.container.fitToViewport();
            }
        });
    });
}
const mapModal = document.getElementById("popup-map__modal");
if (mapModal) mapModal.addEventListener("shown.bs.modal", () => {
    popupMap.container.fitToViewport();
});
export { placemarks };