import "htmx.org";
import Swiper from "swiper";
import { sliderParams } from "./sliders";
import SlimSelect from "slim-select";
import LazyLoad from "vanilla-lazyload";
import { createSliders } from "../modules/functions";
import { Datepicker } from "vanillajs-datepicker";
import { datepickerOptions } from "./datepicker";
import { pickerOptions } from "./datepicker";
import ru from "vanillajs-datepicker/locales/ru";
import moment from "moment";

const dynamicImages = document.querySelectorAll(".lazy-dynamic");
const lazyDynamic = new LazyLoad({
    elements_selector: ".lazy-dynamic"
}, dynamicImages);
window.lazyDynamic = lazyDynamic;
window.blockOffset = "-170px";
window.htmx = require("htmx.org");
window.htmx.config.useTemplateFragments = true;
Object.assign(Datepicker.locales, ru);
moment.locale("ru");

document.body.addEventListener("htmx:configRequest", function (evt) {
    const request = evt.detail;
    //console.log(evt.detail);
    if (typeof (request.elt) !== "undefined") {
        const source = request.elt;
        if (typeof (source.slim) !== "undefined" && source.slim.nodeName === "SELECT" && source.slim.multiple === true) {
            if (source.slim.name) {
                if (typeof (request.parameters[source.slim.name]) !== "undefined") {
                    request.parameters[source.slim.name] = source.slim.getSelected();
                }
            }
        }
    }
    if (evt.detail.path == "ajaxdata2" && typeof evt.detail.elt[0] !== "undefined") {
        evt.detail.parameters["specialName"] = evt.detail.elt[0].selectedOptions[0].innerText;
    }
    if (~request.path.indexOf("ajax/doctors/getlist")) {
        const target = request.target;
        if (target) {
            createSliders(target);
        } else {
            if (typeof evt.detail.triggeringEvent !== "undefined" && typeof evt.detail.triggeringEvent.srcElement !== "undefined") {
                evt.detail.parameters["source"] = (evt.detail.triggeringEvent.srcElement.dataset.name) ? evt.detail.triggeringEvent.srcElement.dataset.name : "";
            }
        }
    }
});

window.htmx.onLoad(function (elt) {
    //selects
    const selects = elt.querySelectorAll("select._select");
    for (const select of selects) {
        if (typeof select.slim === "undefined") {
            new SlimSelect({
                select: select,
                settings: {
                    showSearch: (typeof select.dataset.search != "undefined" && select.dataset.search != "") ? true : false,
                    searchText: "Нет результатов",
                    searchingText: "Поиск...",
                    searchPlaceholder: select.dataset.search,
                    maxValuesShown: 3,
                    maxValuesMessage: "{number} выбрано",
                    allowDeselect: (typeof select.dataset.deselect != "undefined") ? false : true,
                    disabled: true,
                },
                events: {
                    beforeChange: (newVal, oldVal) => {
                        //console.log({ newVal: newVal, oldVal: oldVal, el: select });
                        if (newVal != oldVal) return true; else return false;
                    }
                }
            });
        }
        select.slim.enable();
    }

    //sliders
    if (typeof elt !== "undefined" && elt.nodeType == 1) createSliders(elt);
    window.lazyDynamic.update();
    //console.log(window.lazyDynamic);
});
document.body.addEventListener("htmx:afterRequest", function () {
    const picker = document.querySelector("input._datepicker");
    if (picker) {
        const changers = document.querySelectorAll(".date-changer");
        if (changers) {
            //console.log(changers);
            for (const changer of changers) {
                changer.addEventListener("click", function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    const target = e.currentTarget;
                    if (typeof picker.datepicker === "undefined") new Datepicker(picker, datepickerOptions);
                    const dp = picker.datepicker;
                    let date = moment(dp.getDate("dd.mm.yyyy"), "DD.MM.YYYY");
                    if (target.classList.contains("date-prev")) date.subtract(1, "days");
                    if (target.classList.contains("date-next")) date.add(1, "days");
                    if (dp) dp.setDate(date.format("DD.MM.YYYY"), pickerOptions);
                });
            }
        }
    }
});

document.body.addEventListener("htmx:afterOnLoad", function (evt) {
    const target = evt.detail.target;
    const sliders = target.querySelectorAll(".swiper");
    if (sliders) {
        for (const slider of sliders) {
            //console.log(slider.dataset.slider);
            if (typeof slider.swiper !== "undefined") {
                //slider.swiper.destroy();
            }
        }
    }
    const picker = document.querySelector("input._datepicker");
    if (picker) {
        if (typeof picker.datepicker === "undefined") new Datepicker(picker, datepickerOptions);
    }
});
document.body.addEventListener("htmx:afterSwap", function (evt) {
    const target = evt.detail.target;
    if (typeof target !== "undefined" && target.nodeType == 1) createSliders(target);
    //console.log(evt.detail);
    const path = evt.detail.pathInfo.requestPath;
    if (~path.indexOf("ajax-data/doctors/getcards")) {
        //console.log("ajax-data/doctors/getcards");
        window.htmx.process("#doctorsList");
    }
    const container = document.querySelector("._image__container");
    if (container) {
        const ajax_images = new LazyLoad({
            container: document.querySelector("._image__container")
        });
        ajax_images.update();
    }
    if (typeof target !== "undefined" && target.nodeType == 1) createSliders(target);

});
document.addEventListener("DOMContentLoaded", () => {
    const bblocks_parent = document.querySelectorAll(".page__content-links");
    if (bblocks_parent) {
        let array = [...bblocks_parent];
        array.forEach(element => {
            const links = element.querySelectorAll(".button__block");
            if (links.length > 0) {
                for (let link of links) {
                    if (link.dataset.block) {
                        let block = document.getElementById(link.dataset.block);
                        if (block === null || typeof block === "undefined") {
                            link.remove();
                        } else if (block.classList.contains("d-none")) {
                            link.classList.add("disabled");
                        }
                    }
                }
            }
        });
    }
});
document.body.addEventListener("htmx:afterRequest", function (evt) {
    const target = evt.detail.target;
    let response = evt.detail.xhr.response;
    if (typeof target !== "undefined" && target.classList.contains("page-block") && response !== "") {
        let id = target.id;
        if (id) {
            const parser = new DOMParser();
            const section = parser.parseFromString(response, "text/html");
            let blocks = document.querySelectorAll(".button__" + id);
            let array = [...blocks];
            array.forEach(element => {
                if (typeof section.querySelector("#" + target.id) !== "undefined") {
                    if (section.querySelector("#" + target.id).classList.contains("d-none")) {
                        element.classList.add("disabled");
                    } else {
                        element.classList.remove("disabled");
                    }
                }
            });
        }
    }
});